// HomePage.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage: React.FC = () => {
  return (
    <div>
      <header style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
        <h1>My Website</h1>
        <nav>
          <Link to="/">Home</Link> | 
          <Link to="/about">About Us</Link> | 
          <Link to="/contact">Contact</Link> | 
          <Link to="/signup">Sign Up</Link>
        </nav>
      </header>

      <section style={{ padding: "20px", textAlign: "center" }}>
        <h2>Welcome to Our Website!</h2>
        <p>Explore our services and offerings.</p>
        <button style={{ padding: "10px 20px", fontSize: "16px" }}>
          <Link to="/about">Learn More</Link>
        </button>
      </section>

      <section style={{ padding: "20px", display: "flex", gap: "10px" }}>
        <div style={{ flex: 1, padding: "10px", border: "1px solid #ccc" }}>
          <h3>Feature 1</h3>
          <p>Discover our top features.</p>
        </div>
        <div style={{ flex: 1, padding: "10px", border: "1px solid #ccc" }}>
          <h3>Feature 2</h3>
          <p>Find out more about our offerings.</p>
        </div>
        <div style={{ flex: 1, padding: "10px", border: "1px solid #ccc" }}>
          <h3>Feature 3</h3>
          <p>Enjoy exclusive benefits.</p>
        </div>
      </section>

      <footer style={{ padding: "10px", borderTop: "1px solid #ccc", textAlign: "center" }}>
        <p>&copy; 2024 My Website. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;
