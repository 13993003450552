import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Home';
import AboutUs from './AboutUs';
import Layout from './Layout';
function App(){

 

  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route  path="/about" element={<AboutUs />} />
       </Route>
    </Routes>
      </BrowserRouter>
  );

}

export default App;