// Signup.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AboutUs= () => {
 
  const navigation=useNavigate();

  const btnClick=() =>{
    console.log("Button clicked");
    navigation('/');
  }
  
  return (
    <div>
       AboutUs

       <button onClick={btnClick}>Go to home page</button>

   </div>
  );
};

export default AboutUs;
