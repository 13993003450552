// Layout.tsx
import React from 'react';
import { Outlet, Link } from 'react-router-dom';

const Layout = () => {
  return (
    <div>
      <header>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
          </ul>
        </nav>
      </header>

      <main>
        <Outlet /> {/* This is where the child routes will be rendered */}
      </main>

      <footer>
        <p>© 2024 My App</p>
      </footer>
    </div>
  );
};

export default Layout;
